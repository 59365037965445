'use client';
import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { useIsAllowed } from '@/lib/RBAC';
import { useJibbleClockInMutation } from '@/hooks/fetchers/mutations/admins/useJibbleClockInMutation';
import { toast, ToastTypeEnums } from './toast';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';

const JibbleClockInDialog = () => {
  const t = useTranslations();
  const { checkPermissions, session } = useIsAllowed();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { mutate: clockIn, isPending: clockInPending } = useJibbleClockInMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
      });
      setIsOpen(false);
    },
  });

  useEffect(() => {
    if (checkPermissions([PERMISSIONS_TYPES.VIEW_JIBBLE_TRACKER_OPTIONS])) {
      if (session?.user.jibbleStatus === 'Inactive') {
        setIsOpen(true);
      }
    }
  }, [JSON.stringify(session)]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent closeOnlyByAction className="w-full max-w-[480px]">
        <DialogHeader>
          <DialogTitle>🔒 {t('Pages.JibbleClockInDialog.title')}</DialogTitle>
          <DialogDescription>{t('Pages.TherapistAttestation.description')}</DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex h-20 flex-row items-center justify-between space-x-2 sm:justify-between">
          <Button
            type="button"
            size="lg"
            className="min-w-32"
            disabled={clockInPending}
            onClick={() => clockIn({ userId: session?.user.userId as string, auto: true, activity: 'work' })}
          >
            {t('Buttons.clockIn')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

JibbleClockInDialog.displayName = 'JibbleClockInDialog';

export { JibbleClockInDialog };
